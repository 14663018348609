import { Row,Col } from "react-bootstrap";

function BlogContainer() {
  return (
    <>
    <Row>
        <Col>
            hi
        </Col>
    </Row>
    </>
  );
}

export default BlogContainer;
