import {Col, Container, Row} from "react-bootstrap";

function UIdesigns() {
    return (
        <Container >
            <Row className="project-row my-3 px-4">
                <Col className="abt-tab-col my-2 py-3 px-4 mx-2">
                    <div className="abt-tab px-1">
                        <h4 style={{color:"#9818f3"}}>ArcheloLAB Platform</h4>
                    </div>
                    <div className="pt-3">
                        <h6 style={{color:"#bebabe"}}>
                            <h5><b>Platform for construction field</b></h5><br/>
                            As the lead frontend developer, I contributed to the ArcheloLAB platform, a client project
                            initiated by archelolab (pvt) Ltd. This web app serves as a platform connecting individuals
                            seeking architectural job roles with those who require architectural services.
                            <span style={{color:"#9818f3"}}>React.js, Redux, React Bootstrap, JWT, axios, react-chartjs-2, GitHub, Figma </span>
                            are some key technologies used in this project
                        </h6><br/>
                        <div className="py-3 ms-1">
                        <a href="https://beta.archelolab.com/"
                           target="_blank"
                           rel="noopener noreferrer">
                            <button className="visit-website-btn px-3 py-1">
                                Visit Website
                            </button> </a>
                    </div>
                    </div>
                </Col>
            </Row>
            <Row className="project-row my-3 px-4">
                <Col className="abt-tab-col my-2 py-3 px-4 mx-2">
                    <div className="abt-tab px-1">
                        <h4 style={{color:"#9818f3"}}>findmeds.lk Platform</h4>
                    </div>
                    <div className="pt-3">
                        <h6 style={{color:"#bebabe"}}>
                            <h5><b>Platform for find medicine</b></h5><br/>
                            I actively contributed as a frontend developer and project manager for a charitable platform
                            aimed at connecting medicine seekers with volunteers. Currently, the web app is undergoing
                            development and the Minimum Viable Product (MVP) is set to launch soon.
                            <span style={{color:"#9818f3"}}>React.js, React Bootstrap, JWT, axios, Node.js, Firebase, GitHub </span>
                            are some key technologies used in this project
                        </h6><br/>
                        <div className="py-3 ms-1">
                        <a href="https://findmeds.lk/"
                           target="_blank"
                           rel="noopener noreferrer">
                            <button className="visit-website-btn px-3 py-1">
                                Visit Website
                            </button> </a>
                    </div>
                    </div>
                </Col>
            </Row>

            <Row className="project-row px-4">
                <Col className="abt-tab-col my-2 py-3 px-4 mx-2">
                    <div className="abt-tab px-1">
                        <h4 style={{color:"#9818f3"}}>Game Arcade</h4>
                    </div>
                    <div className="pt-3">
                        <h6 style={{color:"#bebabe"}}>
                            <h5><b>Collection of gaming webapp</b></h5><br/>
                            As the frontend developer for the Game Arcade client project, I contributed to developing a mobile-responsive web-based gaming platform. The project is set to launch soon.
                            <span style={{color:"#9818f3"}}>React.js and React Bootstrap</span>
                            are the key technologies used in this project
                        </h6><br/>
                        {/* <div className="py-3 ms-1">
                        <a href="https://beta.archelolab.com/"
                           target="_blank"
                           rel="noopener noreferrer">
                            <button className="visit-website-btn px-3 py-1">
                                Visit Website
                            </button> </a>
                    </div> */}
                    </div>
                </Col>
            </Row>
            <Row className="project-row my-3 px-4">
                <Col className="abt-tab-col my-2 py-3 px-4 mx-2">
                    <div className="abt-tab px-1">
                        <h4 style={{color:"#9818f3"}}>examcarts.com (Web Application)</h4>
                    </div>
                    <div className="pt-3">
                        <h6 style={{color:"#bebabe"}}>
                            <h5><b>Online Exam Management System</b></h5><br/>
                            examcarts.com is a online exam management platform which connect teachers, students and paper marking
                            instructors. This system is a web based system developed following MERN Stack.
                            <span style={{color:"#9818f3"}}> React Typescript,
                                Node Js, Mongo DB, Express Js, SCSS, React Bootstrap, React Suite, Cloudinary, Node Mailer </span>
                            are some of the core libraries and services used for develop this system. This is an individual
                            project and now it's in the final stage in development.
                        </h6><br/>
                        <h6><b>Watch the UX/UI Prototype Design Demonstration which designed using <span style={{color:"#9818f3"}}>
                            Adobe XD and Adobe photoshop. </span>All the components of this prototype are designed from scratch.</b></h6><br/>
                    </div>
                    <div className="iframe-container text-center px-4">
                        <iframe width="840" height="473" src="https://www.youtube.com/embed/lT1aFdjK4WM"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>
                    </div>
                </Col>
            </Row>
            
            <Row className="project-row my-3 px-4">
                <Col className="abt-tab-col my-2 py-3 px-4 mx-2">
                    <div className="abt-tab px-1">
                        <h4 style={{color:"#9818f3"}}>Isuru M Soysa - Portfolio Prototype</h4>
                    </div>
                    <div className="pt-3">
                        <h6 style={{color:"#bebabe"}}>
                            <h5><b>Portfolio Website</b></h5><br/>
                            This is the UI/UX prototype design of the website now you are browsing.
                            I used <span style={{color:"#9818f3"}}> Figma software </span> for design the prototype.
                            This project developed using <span style={{color:"#9818f3"}}>  React. </span>

                        </h6><br/><br/>

                        <h6><b>Watch the UX/UI Demonstration of this website.</b></h6><br/>
                    </div>
                    <div className="iframe-container text-center px-4">
                        <iframe width="840" height="473" src="https://www.youtube.com/embed/9jgVBV7HiHI"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>
                    </div><br/>
                </Col>
            </Row>

            <Row className="project-row px-4">
                <Col className="abt-tab-col my-2 py-3 px-4 mx-2">
                    <div className="abt-tab px-1">
                        <h4 style={{color:"#9818f3"}}>Marketing Evaluation Management System</h4>
                    </div>
                    <div className="pt-3">
                        <h6 style={{color:"#bebabe"}}>
                            <h5><b>System for update and review KPIs</b></h5><br/>                         
                            Project done for Advantis 3PL Plus for update, evaluate and visualize the business KPIs.
                            <span style={{color:"#9818f3"}}>Microsoft PowerBI, Microsoft SharePoint, Microsoft PowerAutomation</span>
                            are the key technologies used in this project
                        </h6><br/>
                    </div>
                </Col>
            </Row>
            <Row className="project-row my-3 px-4">
                <Col className="abt-tab-col my-2 py-3 px-4 mx-2">
                    <div className="abt-tab px-1">
                        <h4 style={{color:"#9818f3"}}>Lake View Cottage (Web Application)</h4>
                    </div>
                    <div className="pt-3">
                        <h6 style={{color:"#bebabe"}}>
                            <h5><b>Responsive Business Website</b></h5><br/>
                            Lake view cottage is a beautiful cottage in Rikillagaskada and we developed their business
                            portfolio website. I was the <span style={{color:"#9818f3"}}> front-end developer</span>
                            of this project and <span style={{color:"#9818f3"}}>  I used HTML, CSS, and Bootstrap </span>
                            for the front end. This is a <span style={{color:"#9818f3"}}> responsive website and the first
                            website I used Bootstrap for UI/UX.</span>
                        </h6><br/>
                        <h6>
                            <span style={{color:"#9818f3"}}>
                                <b>Front End Developer :</b> Isuru M Soysa<br/>
                                <b>Back End Developer :</b> Thilina Asiri Aluthge<br/>
                                <b>Used technologies :</b> HTML, CSS, bootstrap, Ejs</span><br/><br/>
                        </h6>
                        <h6><b>Watch the UX/UI Demonstration of Lake View Cottage Website.</b></h6><br/>
                    </div>
                    <div className="iframe-container text-center px-4">
                        <iframe width="840" height="473" src="https://www.youtube.com/embed/vYUbFZvM_LI"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>
                    </div><br/>
                    <div className="py-3 ms-1">
                        <a href="http://lakeviewcottage.net/"
                           target="_blank"
                           rel="noopener noreferrer">
                            <button className="visit-website-btn px-3 py-1">
                                Visit Website
                            </button> </a>
                    </div>
                </Col>
            </Row>

            <Row className="project-row my-3 px-4">
            <Col className="abt-tab-col my-2 py-3 px-4 mx-2">
                <div className="abt-tab px-1">
                    <h4 style={{color:"#9818f3"}}>Malinga Herath Photography (Web Application)</h4>
                </div>
                <div className="pt-3">
                    <h6 style={{color:"#bebabe"}}>
                        <h5><b>Portfolio & Online Ordering Website</b></h5><br/>
                        This Project included the gallery view and admin panel also. I contributed as a
                        <span style={{color:"#9818f3"}}> front-end developer</span> to this project.<br/>
                        The speciality behind this project was the front-end developed using
                        <span style={{color:"#9818f3"}}> only HTML and CSS.</span>
                    </h6><br/>
                    <h6>
                            <span style={{color:"#9818f3"}}>
                                <b>UI/UX Designer :</b> Isuru M Soysa<br/>
                                <b>Front End Developers(Homepage and Admin Panel) :</b> Isuru M Soysa, Malinga Herath<br/>
                                <b>Front End Developers(Gallery Page) :</b>  Thuvarakan Suppaiah <br/>
                                <b>Back End Developers :</b> Kalana Thathsara, Sarada Withanarachchi<br/>
                                <b>Used technologies :</b>  HTML, CSS, Javascript, PHP</span>
                    </h6><br/>
                    <h6><b>Watch the  proposed UI/UX demonstration for Malinga Herath Photography Portfolio Website (Homepage).
                    </b></h6><br/>
                </div>
                <div className="iframe-container text-center px-4">
                    <iframe width="840" height="473" src="https://www.youtube.com/embed/liqy5PDu3As"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
            </Col>
        </Row>

            <Row className="project-row my-3 px-4">
                <Col className="abt-tab-col my-2 py-3 px-4 mx-2">
                    <div className="abt-tab px-1">
                        <h4 style={{color:"#9818f3"}}>IM ASSIST (Mobile Application)</h4>
                    </div>
                    <div className="pt-3">
                        <h6 style={{color:"#bebabe"}}>
                            <h5><b>Academic Assisting Mobile App for Undergraduates</b></h5><br/>
                            IM Assist is a mobile application proposed to the undergraduates of the Department of
                            Industrial Management, University of Kelaniya. I was able to contribute as a
                            <span style={{color:"#9818f3"}}> UX/UI Designer and a Front-end developer</span> of this project.<br/>
                            For the front-end, we have used
                            <span style={{color:"#9818f3"}}>Xamarin Forms, C#, and Visual Studio IDE.</span>
                            This video demonstrates only the UI/UX part before connecting the database.
                        </h6><br/>
                        <h6>
                            <span style={{color:"#9818f3"}}>
                                <b>UI/UX Designer :</b> Isuru M Soysa<br/>
                                <b>Front End Developers :</b> Isuru M Soysa, Tharindu Lakmal<br/>
                                <b>Back End Developers :</b>  Isuru Madumadhawa <br/>
                                <b>UML Designers :</b>  Bashith Piyumal, Tharindu Chaminda, Tharindu Lakmal, Aruni Prasangika <br/>
                                <b>Graphic Designer and Documentation :</b>  Tharindu Chaminda<br/>
                                <b>Used Technologies :</b>  Xamarin Forms, C#, Visual Studio IDE, Node JS, Mongo DB<br/>
                            </span>
                        </h6><br/>
                        <h6><b>Watch the  proposed UI/UX demonstration for IM Assist Mobile App.
                        </b></h6><br/>
                    </div>
                    <div className="iframe-container text-center px-4">
                        <iframe width="840" height="473" src="https://www.youtube.com/embed/Pvlj0cpE2dk"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>
                    </div>
                </Col>
            </Row>

            <Row className="project-row my-3 px-4">
                <Col className="abt-tab-col my-2 py-3 px-4 mx-2">
                    <div className="abt-tab px-1">
                        <h4 style={{color:"#9818f3"}}>Liyane Arts (Web Application)</h4>
                    </div>
                    <div className="pt-3">
                        <h6 style={{color:"#bebabe"}}>
                            <h5><b>Responsive Portfolio Website</b></h5><br/>
                            This was one of my first projects contributed as the
                            <span style={{color:"#9818f3"}}> Front-end developer</span>
                            The specialty of this project is <span style={{color:"#9818f3"}}>
                            I used only HTML and CSS for the front end with mobile responsiveness.
                            </span>
                        </h6><br/>
                        <h6>
                            <span style={{color:"#9818f3"}}>
                                <b>Front End Developer :</b> Isuru M Soysa<br/>
                                <b>Back End Developer :</b> Thilina Asiri Aluthge<br/>
                                <b>Used technologies :</b> HTML, CSS, Ejs</span><br/><br/>
                        </h6>
                        <h6><b>Watch the UX/UI Demonstration of Liyane Arts Portfolio Website.</b></h6><br/>
                    </div>
                    <div className="iframe-container text-center px-4">
                        <iframe width="840" height="473" src="https://www.youtube.com/embed/B4_uMXURDQs"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>
                    </div><br/>
                    <div className="py-3 ms-1">
                        <a href=" https://liyane-arts.herokuapp.com/"
                           target="_blank"
                           rel="noopener noreferrer">
                            <button className="visit-website-btn px-3 py-1">
                                Visit Website
                            </button> </a>
                    </div>
                </Col>
            </Row>

        </Container>


    );
}

export default UIdesigns;
